<template>
    <div class="map-container container py-5">
        <div class="filter-wrap">
            <!-- <div class="category" v-if="CategoryList">
                <b-form-select v-model="types" @change="selectedAddress">
                    <b-form-select-option
                        v-for="item in CategoryList.results"
                        :value="item.id"
                        >{{ item.name }}
                    </b-form-select-option>
                </b-form-select>
            </div> -->
            <!-- <div class="locations" v-if="AddressList && AddressList.results">
                <div
                    class="address-wrap"
                    v-for="(location, index) in AddressList.results"
                    :key="index"
                    @click="currentLocation = index"
                >
                    <div class="tit">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                            className="text-[30px]"
                        >
                            <path
                                fill="currentColor"
                                d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
                            />
                        </svg>
                        <h3 class="label">
                            {{ location.name }}
                        </h3>
                    </div>
                    <div class="address">
                        {{ location.physical_address }}
                    </div>
                </div>
                <div class="no-address" v-if="AddressList.results.length < 1">
                    {{ $t('noDataTryAgain') }}
                </div>
            </div> -->
            <div class="locations" v-if="locations">
                <div
                    class="address-wrap"
                    v-for="(location, index) in locations"
                    :key="index"
                    @click="currentLocation = index"
                >
                    <div class="tit">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                            className="text-[30px]"
                        >
                            <path
                                fill="currentColor"
                                d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
                            />
                        </svg>
                        <h3 class="label">
                            {{ location.label }}
                        </h3>
                    </div>
                    <div class="address">
                        {{ location.address }}
                    </div>
                </div>
                <div class="no-address" v-if="locations.length < 1">
                    {{ $t('noDataTryAgain') }}
                </div>
            </div>
            <div v-else>Loading</div>
        </div>
        <div class="map">
            <!-- <MapView
                v-if="AddressList"
                :poi="AddressList.results[currentLocation]"
                :pois="AddressList.results"
                :key="AddressList.results[currentLocation]"
            /> -->

            <MapView
                v-if="locations"
                :poi="locations[currentLocation]"
                :pois="locations"
                :key="locations[currentLocation].label"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MapView from './map/MapView.vue';

export default {
    components: {
        MapView,
    },
    computed: {
        ...mapGetters({
            CategoryList: 'map/CategoryList',
            AddressList: 'map/AddressList',
        }),
    },
    data() {
        return {
            locations: [
                {
                    label: 'Enterprise Node Calmete',
                    address:
                        '4-8 Đ. Calmette, Phường Nguyễn Thái Bình, Quận 1, Hồ Chí Minh, Việt Nam',
                    latitude: 10.766720057280606,
                    longitude: 106.7005612890853,
                },
                {
                    label: 'Enterprise Node Kontum',
                    address: '446A Nguyễn Huệ, Thống Nhất, Kon Tum, Vietnam',
                    latitude: 14.345927560457309,
                    longitude: 108.01062553524261,
                },
                {
                    label: 'Enterprise Node Hà nội',
                    address:
                        'A01-L60 An Vượng Villa, Dương Nội, Hà Đông, Hà Nội',
                    latitude: 20.98372620431982,
                    longitude: 105.75402610190959,
                },
            ],

            currentLocation: 0,

            type: 'enterprisenode',
            types: '66f4df858a8ae458589ba74e',

            selected: null,
        };
    },

    methods: {
        async selectedAddress(input) {
            this.$store.dispatch('map/getAddressList', {
                category_id: input,
            });
        },
    },

    mounted() {
        this.$store.dispatch('map/getCategoryList');
        this.$store.dispatch('map/getAddressList', {
            category_id: '66f4df858a8ae458589ba74e',
        });
    },
};
</script>

<style lang="scss" scoped>
.map-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;

    .locations {
        height: 500px;
        overflow: auto;
        margin-top: 1rem;

        .address-wrap {
            background: #1478b0;
            outline: none;
            padding: 15px;
            position: relative;
            border-radius: 22px;
            margin-bottom: 10px;
            max-width: 400px;
            cursor: pointer;
            .tit {
                display: flex;
                align-items: center;
                gap: 5px;
                font-style: italic;
                svg {
                    font-size: 16px;
                    color: #fff;
                }

                h3 {
                    font-size: 16px;
                    color: #fff;
                    font-weight: 600;
                    margin-bottom: 0;
                }
            }

            .address {
                margin-top: 10px;
                font-size: 18px;
                color: #6dceff;
            }
        }

        .no-address {
            font-size: 40px;
            color: #0087cc;
            font-weight: 600;
        }
    }

    .category {
        display: flex;
        justify-content: space-between;

        > div {
            .icon {
                border-radius: 50%;
                background: #e6e9f0;
                padding: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
            }

            cursor: pointer;
        }
    }

    .map {
        width: 70%;
    }

    @media (max-width: 769px) {
        display: block;

        .locations {
            height: 250px;
            overflow-y: auto;

            .address-wrap {
                max-width: 100%;
                min-height: 120px;
            }
        }

        .map {
            width: 100%;
            margin-top: 2rem;
            .vue-map-container {
                width: 100% !important;
                min-height: 400px !important;
            }
        }
    }
}

// .locations {
//     overflow-x: scroll;
//     display: flex;
//     flex-grow: 1;
//     gap: 16px;
//     > div {
//         background: #1478b0;
//         outline: none;
//         padding: 15px;
//         position: relative;
//         border-radius: 22px;
//         margin-bottom: 10px;
//         max-width: 400px;
//         width: 100%;
//         flex-shrink: 0;
//         cursor: pointer;
//         .tit {
//             display: flex;
//             align-items: center;
//             gap: 5px;
//             font-style: italic;
//             svg {
//                 font-size: 16px;
//                 color: #fff;
//             }

//             .label {
//                 font-size: 18px;
//                 color: #fff;
//                 font-weight: 600;
//                 margin-bottom: 0;
//             }
//         }

//         .address {
//             font-size: 16px;
//             line-height: 1;
//             color: #6dceff;
//             margin-top: 12px;
//         }
//     }
// }

// .filter-wrap {
//     gap: 16px;
//     @media (min-width: 768px) {
//         display: flex;
//     }
//     .filters {
//         padding-top: 8px;
//         padding-bottom: 8px;
//         @media (min-width: 768px) {
//             min-width: 280px;
//         }
//     }
// }
</style>
