var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"utility-history"},[_c('h2',[_vm._v(_vm._s(_vm.$t('history')))]),_c('div',{staticClass:"user-voucher-container"},[_c('div',{staticClass:"base-table",attrs:{"id":"util-table"}},[(_vm.History && _vm.History.results)?_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.History.results,"show-empty":"","fields":_vm.fields,"thead-class":"custom-header","responsive":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-3"},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")])]},proxy:true},{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell()",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [(item.category)?_c('span',[_vm._v(" "+_vm._s(item.category)+" "),_c('br'),_vm._v(" "+_vm._s(item.type_category)+" ")]):(typeof item.type_payment === 'string')?_c('span',[_vm._v(" "+_vm._s(item.type_payment.toUpperCase())+" "),_c('br'),_vm._v(" "+_vm._s(item.type_category)+" ")]):_vm._e()]}},{key:"cell(paymentAmount)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"flex-column"},[(item.bill_value)?_c('div',[_vm._v(" "+_vm._s(Number(item.bill_value).toLocaleString('en-US'))+" VNĐ ")]):_vm._e(),(item.xinCoin)?_c('div',[_vm._v(" "+_vm._s(Number(item.xinCoin).toLocaleString('en-US'))+" "),_c('img',{attrs:{"width":"20","src":require("@/assets/images/logo/xin.png")}})]):_vm._e()])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.type_payment === 'check')?[(item.status === -1)?_c('span',{staticClass:"status-color failed"},[_vm._v(" "+_vm._s(_vm.$t('failed'))+" ")]):(item.status === 2)?_c('span',{staticClass:"status-color success"},[_vm._v(" "+_vm._s(_vm.$t('paymentSuccess'))+" ")]):(item.status === 1)?_c('span',{staticClass:"status-color pending"},[_vm._v(" "+_vm._s(_vm.$t('processingTransaction'))+" ")]):(item.status === 0 && item.bill_value)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('paymentAvailable'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('inquiringBill'))+" ")])]:(item.type_payment === 'payment')?[(item.status === -1)?_c('span',{staticClass:"status-color failed"},[_vm._v(" "+_vm._s(_vm.$t('failed'))+" ")]):(item.status === 2)?_c('span',{staticClass:"status-color success"},[_vm._v(" "+_vm._s(_vm.$t('paymentSuccess'))+" ")]):(item.status === 1)?_c('span',{staticClass:"status-color pending"},[_vm._v(" "+_vm._s(_vm.$t('processingTransaction'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('inquiringBill'))+" ")])]:[(item.status === 0)?_c('span',{staticClass:"status-color pending"},[_vm._v(" "+_vm._s(_vm.$t('processingTransaction'))+" ")]):(item.status === 1)?_c('span',{staticClass:"status-color success"},[_vm._v(" "+_vm._s(_vm.$t('paymentSuccess'))+" ")]):(item.status === -1)?_c('span',{staticClass:"status-color failed"},[_vm._v(" "+_vm._s(_vm.$t('failed'))+" ")]):_vm._e()]]}},{key:"cell(#)",fn:function(row){return [_c('div',[_c('b-button',{staticClass:"px-2 py-0",attrs:{"size":"sm","variant":"info"},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.detailsShowing ? _vm.$t('hide') : _vm.$t('show'))+" ")])],1)]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"more-details details"},[(item.currency === 'xinCoin')?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('payWith'))+":")]),_c('div',{staticClass:"value"},[_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"18px"}})])]):_vm._e(),(item.currency === 'USDT')?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('payWith'))+":")]),_c('div',{staticClass:"value"},[_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"18px"}})])]):_vm._e(),(
                                item.bill_value &&
                                item.rateVNDToUSDT &&
                                item.rateUSDTToXin
                            )?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('price')))]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2( item.bill_value / item.rateVNDToUSDT / item.rateUSDTToXin ))+" "),(item.currency === 'xinCoin')?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"18px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"18px"}})])]):_vm._e(),(item.bill_value)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('cost')))]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(item.bill_value))+" VNĐ ")])]):_vm._e(),(item.fullName)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('name')))]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(item.fullName)+" ")])]):_vm._e(),(item.ky)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('period')))]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(item.ky)+" ")])]):_vm._e(),(item.message)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('comment'))+":")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(item.message)+" ")])]):_vm._e(),(item.createdAt)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('createdDate'))+":")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getDateTime3(item.createdAt))+" ")])]):_vm._e()])]}}],null,false,513972771)}):_vm._e()],1),(_vm.History)?_c('Paginate',{attrs:{"totalPages":_vm.History.totalPages},on:{"current":_vm.onChangePage}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }