<template>
    <div>
        <b-modal
            id="phone-payment"
            hide-footer
            hide-header
            centered
            dialog-class="supa-modal"
        >
            <svg
                @click="$bvModal.hide('phone-payment')"
                class="close"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
            <div class="mi-head">{{ $t('yourPhonePayment') }}</div>
            <div>
                <form class="base-form" @submit.prevent="paymentConfirm">
                    <label>
                        {{ $t('phoneNumber') }}
                        <input v-model="phone" autocomplete="tel" required />
                    </label>
                    <label>
                        {{ $t('paymentType') }}
                        <select v-model="paymentType" required>
                            <option
                                v-for="payment in paymentTypes"
                                :key="payment.value"
                                :value="payment.value"
                            >
                                {{ $t(payment.text) }}
                            </option>
                        </select>
                    </label>
                    <label>
                        {{ $t('amount') }}
                        <span class="tip">
                            ({{ TruncateToDecimals2(10000) }} VNĐ -
                            {{ TruncateToDecimals2(2000000) }} VNĐ)
                        </span>
                        <input
                            v-model="amount"
                            type="number"
                            min="0"
                            step="10000"
                            max="2000000"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            required
                        />
                        <div class="d-flex justify-content-between flex-wrap px-md-3">
                            <div class="tip">
                                {{ TruncateToDecimals2(Number(amount)) }} VNĐ
                            </div>
                            <div class="tip red">
                                {{ $t('multipleOf10000') }}
                            </div>
                        </div>
                    </label>
                    <div class="botans">
                        <button class="botan confirm" type="submit">
                            {{ $t('payment') }}
                        </button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal
            id="phone-confirm"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
        >
            <div class="mi-head">{{ $t('confirmPayment') }}?</div>
            <div class="mi-confirm">{{ $t('doYouContinuePayment') }}?</div>
            <div class="confirm-value">
                <div class="item">
                    <div>{{ $t('phoneNumber') }}</div>
                    <div class="value">
                        {{ phone }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('amount') }}</div>
                    <div class="value">
                        {{ TruncateToDecimals2(Number(amount)) }} VNĐ
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('payWith') }}</div>
                    <div class="value">
                        {{ TruncateToDecimals2(VNDToXIN) }}
                        <img src="@/assets/images/logo/xin.png" width="18px" />
                    </div>
                </div>
            </div>
            <div class="botans">
                <button class="botan confirm" @click="postPayment">
                    {{ $t('confirm') }}
                </button>
                <button class="botan" @click="$bvModal.hide('phone-confirm')">
                    {{ $t('cancel') }}
                </button>
            </div>
            <button
                class="close black"
                @click="$bvModal.hide('phone-confirm')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'PhonePayment',
    data() {
        return {
            phone: '',
            paymentTypes: [
                {
                    value: 'PAY_BEFORE',
                    text: 'payNow',
                },
                {
                    value: 'PAY_AFTER',
                    text: 'payLater',
                },
            ],
            paymentType: 'PAY_BEFORE',
            setAmount: [
                {
                    value: 10000,
                    text: '10,000 VNĐ',
                },
                {
                    value: 20000,
                    text: '20,000 VNĐ',
                },
                {
                    value: 50000,
                    text: '50,000 VNĐ',
                },
                {
                    value: 100000,
                    text: '100,000 VNĐ',
                },
            ],
            amount: null,
            pending: false,
            VNDToXIN: 0,
        };
    },
    methods: {
        async postPayment() {
            if (this.pending) {
                return;
            }
            this.pending = true;
            this.$store
                .dispatch('utilities/getBill', {
                    customer_code: this.phone,
                    type_category: this.paymentType,
                    bill_value: this.amount,
                    type_payment: 'phone',
                    image: 'pep',
                })
                .then((res) => {
                    this.$bvModal.hide('phone-confirm');
                    if (res.status) {
                        this.$bvModal.hide('phone-payment');
                        this.$store.dispatch('info/req_getInfo');
                    }
                })
                .finally(() => {
                    this.pending = false;
                });
        },
        async paymentConfirm() {
            const response = await this.$store.dispatch(
                'wallet/getVNDToXIN',
                this.amount,
            );
            if (response.status) {
                this.VNDToXIN = response.data;
            }
            this.$bvModal.show('phone-confirm');
        },
    },
};
</script>

<style lang="scss" scoped>
.mi-head {
    font-size: 24px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }
    @media (min-width: 768px) {
        font-size: 30px;
    }
}
.mi-confirm {
    font-size: 18px;
    text-align: center;
    padding: 15px 0px;
}
.botans {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    .botan {
        color: #0087cc;
        border: 1px solid currentColor;
        border-radius: 100vw;
        padding: 8px 15px;
        font-size: 20px;
        font-weight: 700;
        flex-grow: 1;
        &.confirm {
            color: #fff;
            background-color: #0087cc;
        }
    }
}
.confirm-value {
    padding: 15px 0px;
    > .item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 18px;
        padding: 2px 0px;
        .value {
            color: #0087cc;
        }
    }
}
.tip {
    color: #0087cc;
    font-size: 0.875em;
    &.red {
        color: #dc2626;
    }
}
</style>
