<script>
import { mapGetters } from 'vuex';

import Paginate from '@/components/shared/Paginate.vue';

export default {
    components: {
        Paginate,
    },
    data() {
        return {
            search: {
                page: 1,
                limit: 20,
                type: 'withdraw',
                fromDate: null,
                toDate: null,
                sortBy: null,
                query: '',
            },
            myFields: {
                commission: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Address',
                        sortable: true,
                        key: 'address',
                    },
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'To',
                        sortable: true,
                        key: 'userId',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                withdraw: [
                    {
                        class: 'text-center',
                        label: 'Address',
                        sortable: true,
                        key: 'address',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Fee',
                        sortable: true,
                        key: 'fee',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Transaction',
                        sortable: true,
                        key: 'transaction',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                deposit: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Fee',
                        sortable: true,
                        key: 'fee',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Transaction',
                        sortable: true,
                        key: 'transaction',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                swap: [
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'To',
                        sortable: true,
                        key: 'to',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount from',
                        sortable: true,
                        key: 'amount_from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount to',
                        sortable: true,
                        key: 'amount_to',
                    },
                    {
                        class: 'text-center',
                        label: 'Fee',
                        sortable: true,
                        key: 'fee',
                    },
                    {
                        class: 'text-center',
                        label: 'Rate',
                        sortable: true,
                        key: 'rate',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                transfer: [
                    {
                        class: 'text-center',
                        label: 'Recieved',
                        sortable: true,
                        key: 'recevied',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Fee',
                        sortable: true,
                        key: 'fee',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                mint: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Claimed',
                        sortable: true,
                        key: 'claimed',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                receive: [
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                historynode: [
                    {
                        label: 'Action',
                        sortable: true,
                        key: 'action',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                claim: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Claimed',
                        sortable: true,
                        key: 'claimed',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                decreamanaeveryday: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'comment',
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'createdAt',
                        key: 'createdAt',
                    },
                ],
                reversecommission: [
                    {
                        class: 'text-center',
                        label: 'commission',
                        key: 'totalCom',
                        sortable: true,
                    },
                    {
                        class: 'text-center',
                        label: 'settled',
                        key: 'paid',
                        sortable: true,
                    },
                    {
                        class: 'text-center',
                        label: 'remaining',
                        key: 'amount',
                        sortable: true,
                    },
                    {
                        class: 'text-center',
                        label: 'currency',
                        key: 'currency',
                        sortable: true,
                    },
                    {
                        class: 'text-center',
                        label: 'comment',
                        key: 'comment',
                        sortable: true,
                    },
                    {
                        class: 'text-center',
                        label: 'status',
                        key: 'status',
                        sortable: true,
                    },
                    {
                        class: 'text-center',
                        label: 'expiryDate',
                        key: 'reservedTime',
                        sortable: true,
                    },
                    {
                        key: '#',
                        label: 'more',
                        class: 'text-center',
                    },
                ],
                historymana: [
                    {
                        class: 'text-center',
                        label: 'type',
                        key: 'manaType',
                    },
                    {
                        class: 'text-center',
                        label: 'receiverId',
                        key: 'receiverId',
                    },
                    {
                        class: 'text-center',
                        label: 'amount',
                        key: 'manaAmount',
                    },
                    {
                        class: 'text-center',
                        label: 'comment',
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'createdAt',
                        key: 'createdAt',
                    },
                ],
                voucher: [
                {
                        class: 'text-center',
                        label: 'amount',
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'type',
                        key: 'type',
                    },
                    {
                        class: 'text-center',
                        label: 'currency',
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'createdAt',
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'comment',
                        key: 'comment',
                    },

                ]
            },
            myMobileFields: {
                commission: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                withdraw: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                deposit: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                swap: [
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'To',
                        sortable: true,
                        key: 'to',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount from',
                        sortable: true,
                        key: 'amount_from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount to',
                        sortable: true,
                        key: 'amount_to',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                transfer: [
                    {
                        class: 'text-center',
                        label: 'Recieved',
                        sortable: true,
                        key: 'recevied',
                    },
                    {
                        class: 'text-center',
                        label: 'Reciever',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                mint: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Claimed',
                        sortable: true,
                        key: 'claimed',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                receive: [
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                historynode: [
                    {
                        label: 'Action',
                        sortable: true,
                        key: 'action',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                claim: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Claimed',
                        sortable: true,
                        key: 'claimed',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                decreamanaeveryday: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'comment',
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'createdAt',
                        key: 'createdAt',
                    },
                ],
                reversecommission: [
                    {
                        class: 'text-center',
                        label: 'remaining',
                        key: 'remainingReserve',
                        sortable: true,
                    },
                    {
                        class: 'text-center',
                        label: 'expiryDate',
                        key: 'reservedTime',
                        sortable: true,
                    },
                    {
                        key: '#',
                        label: 'more',
                        class: 'text-center',
                    },
                ],
                historymana: [
                    {
                        class: 'text-center',
                        label: 'type',
                        key: 'manaType',
                    },
                    {
                        class: 'text-center',
                        label: 'receiverId',
                        key: 'receiverId',
                    },
                    {
                        class: 'text-center',
                        label: 'amount',
                        key: 'manaAmount',
                    },
                    {
                        class: 'text-center',
                        label: 'comment',
                        key: 'comment',
                    },
                    {
                        key: '#',
                        label: 'more',
                        class: 'text-center',
                    },
                ],
                voucher: [
                    {
                        class: 'text-center',
                        label: 'amount',
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'type',
                        key: 'type',
                    },
                    {
                        class: 'text-center',
                        label: 'currency',
                        key: 'currency',
                    },
                    {
                        key: '#',
                        label: 'more',
                        class: 'text-center',
                    },
                ],

            },
            today: null,
            toDateSelected: null,
            currentPage: 1,
            totalPages: 10,
            removedTypes: ['nodegift'],
        };
    },
    watch: {
        'search.type': {
            handler(newVal) {
                if (newVal) {
                    this.search.type = newVal;
                    this.$store.dispatch(
                        'history/req_getHistoryWallet',
                        this.search,
                    );
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            History: 'history/History',
            isNetWork: 'contract/isNetWork',
            ListType: 'history/ListType',
            TotalHistory: 'history/TotalHistory',
        }),
        url() {
            if (this.isNetWork === 'testnet') {
                return 'https://testnet.bscscan.com/tx/';
            }
            return 'https://bscscan.com/tx/';
        },
        FilteredTypes() {
            const myArrays = this.ListType;
            for (let i = 0; i < this.removedTypes.length; i += 1) {
                for (let indexy = 0; indexy < myArrays.length; indexy += 1) {
                    if (myArrays[indexy].value === this.removedTypes[i]) {
                        myArrays.splice(indexy, 1);
                        break;
                    }
                }
            }
            return myArrays;
        },
    },
    methods: {
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('history/req_getHistoryWallet', this.search);
        },
        onContext(ctx) {
            // The date formatted in the locale, or the `label-no-date-selected` string
            this.formatted = ctx.selectedFormatted;
            // The following will be an empty string until a valid date is entered
            this.selected = ctx.selectedYMD;
        },
        onReset() {
            this.search = {
                page: 1,
                limit: 20,
                type: this.ListType[0].value,
                fromDate: null,
                toDate: null,
                sortBy: null,
                query: '',
            };
            this.onChangePage(1);
        },
        detectSort(ctx) {
            if (ctx.sortBy) {
                this.search.sortBy = `${ctx.sortBy}:${
                    ctx.sortDesc ? 'desc' : 'asc'
                }`;
            }
            this.$store.dispatch('history/req_getHistoryWallet', this.search);
        },

        justSearch() {
            this.$store.dispatch('history/req_getHistoryWallet', this.search);
        },
        ManipulateString(input) {
            console.log(input);
            const string = input;
            let subString = '';
            let index = -1;
            switch (this.search.type) {
                case 'payment':
                case 'ticket':
                case 'commission':
                    subString = 'by admin';
                    index = string.indexOf(subString);
                    if (index === -1) {
                        break;
                    }

                    return string.slice(0, index);
                default:
                    break;
            }
            return string;
        },
    },
    created() {
        this.$store.dispatch('history/req_getHistoryWalletType').then((res) => {
            if (res.status) {
                [this.search.type] = res.data;
            }
        });
        this.today = new Date();
    },
};
</script>
<template>
    <!-- <b-container class="activity-tab" v-if="ListType"> -->
    <b-container class="activity-tab" v-if="FilteredTypes">
        <div class="activity-list">
            <div class="filter-history">
                <label for="">{{ $t('activityType') }}</label>
                <div class="group-hist">
                    <select
                        :key="FilteredTypes.length"
                        class="form-control select-type"
                        v-model="search.type"
                    >
                        <option
                            :value="item.value"
                            v-for="item in FilteredTypes"
                            :key="item.value"
                        >
                            {{ $t(`${item.text}`) }}
                        </option>
                    </select>
                    <!-- <div class="mi-input">
                        Search:
                        <form @submit.prevent="justSearch" class="wrap">
                            <input type="text" v-model="search.query" />
                            <button class="submit" type="submit">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    viewBox="0 0 24 24"
                                >
                                    <g fill="none" stroke="currentColor">
                                        <circle cx="11" cy="11" r="6" />
                                        <path
                                            stroke-linecap="round"
                                            d="m20 20l-3-3"
                                        />
                                    </g>
                                </svg>
                            </button>
                        </form>
                    </div> -->
                </div>
            </div>
            <!-- <b-row class="ml-0 w-100 align-items-center">
                <b-col md="4" sm="6" cols="10">
                    <label for="">From Date </label>
                    <div class="group-hist">
                        <b-form-datepicker
                            id="datepicker"
                            v-model="search.fromDate"
                            class="mb-0 date-form"
                            @context="onContext"
                            :min="toDateSelected"
                            :max="today"
                        ></b-form-datepicker>
                    </div>
                </b-col>
                <b-col md="4" sm="6" cols="10">
                    <label for="">To Date </label>
                    <div class="group-hist">
                        <b-form-datepicker
                            id="datepicker-2"
                            v-model="search.toDate"
                            class="mb-0 date-form"
                            @context="onContext"
                            :disabled="!search.fromDate"
                            :min="search.fromDate"
                            :max="today"
                        ></b-form-datepicker>
                    </div>
                </b-col>
                <b-col md="4">
                    <b-row class="ml-0 w-100 align-items-center">
                        <b-col md="3" sm="2" cols="4">
                            <b-button
                                class="mx-1 filter refresh"
                                @click="onReset()"
                            >
                                <img
                                    src="~@/assets/images/icons/Refresh.png"
                                    alt=""
                                />
                            </b-button>
                        </b-col>
                        <b-col md="9" sm="10" cols="8">
                            <b-button class="bede-btn" @click="onChangePage(1)">
                                <p>Search</p>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row> -->
            <!-- ---------------------------------------------------------------------------------- -->
            <div class="d-none d-lg-block table-responsive mt-4">
                <b-table
                    striped
                    hover
                    :items="History.list"
                    :fields="myFields[search.type]"
                    show-empty
                    thead-class="customer-header"
                    no-local-sorting
                    @sort-changed="detectSort"
                >
                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>
                    <template #cell()="{ value }">
                        <div>{{ value }}</div>
                    </template>
                    <template #empty>
                        <h4 class="text-center my-3">{{ $t('noData') }}</h4>
                    </template>
                    <template #cell(transaction)="data">
                        <div>
                            <a
                                v-if="data.item.transaction"
                                target="_blank"
                                :href="`${url}${data.item.transaction}`"
                            >
                                {{
                                    data.item.transaction.slice(0, 5) +
                                    '...' +
                                    data.item.transaction.slice(
                                        data.item.transaction.length - 10,
                                        data.item.transaction.length,
                                    )
                                }}
                            </a>
                        </div>
                    </template>
                    <template #cell(createdAt)="data">
                        <div>
                            {{
                            Number.isInteger(data.item.createdAt) ? getDateTime(
                                data.item.createdAt * 1000)
                                : getDateTime3(data.item.createdAt),

                            }}
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <div v-if="search.type === 'reversecommission'">
                            <div
                                v-if="data.item.status === -1"
                                class="text-danger"
                            >
                                <strong>{{ $t('canceled') }}</strong>
                            </div>
                            <div
                                v-if="data.item.status === 0"
                                class="text-warning"
                            >
                                <strong>{{ $t('pending') }}</strong>
                            </div>
                            <div v-if="data.item.status === 1" class="received">
                                <strong>{{ $t('received') }}</strong>
                            </div>
                            <div
                                v-if="data.item.status === 2"
                                class="text-warning"
                            >
                                <strong>{{ $t('expired') }}</strong>
                            </div>
                        </div>
                        <div v-else>
                            <span
                                :class="
                                    data.item.status ? 'success' : 'text-danger'
                                "
                            >
                                {{ data.item.status ? 'Success' : 'Failed' }}
                            </span>
                        </div>
                    </template>
                    <template #cell(action)="data">
                        <div class="text-uppercase">
                            {{ data.item.action }}
                        </div>
                    </template>
                    <template #cell(currency)="{ value }">
                        <div>
                            <img
                                v-if="
                                    value === 'xinCoin' || value === 'bxinCoin'
                                "
                                src="@/assets/images/logo/xin.png"
                                width="30px"
                            />
                            <img
                                v-else
                                src="@/assets/images/logo/usdt.png"
                                width="30px"
                            />
                        </div>
                    </template>
                    <template #cell(amount)="{ value }">
                        <div>
                            {{ TruncateToDecimals2(value, '', 4) }}
                        </div>
                    </template>
                    <template #cell(expired)="data">
                        <div>
                            {{
                            Number.isInteger(data.item.createdAt) ? getDateTime(
                                data.item.createdAt * 1000)
                                : getDateTime3(data.item.createdAt),

                            }}
                        </div>
                    </template>
                    <template #cell(fee)="{ item, value }">
                        <div>
                            {{ value }}
                            <template v-if="item.to">
                                <img
                                    v-if="
                                        item.to === 'xinCoin' ||
                                        item.to === 'bxinCoin'
                                    "
                                    src="@/assets/images/logo/xin.png"
                                    width="30px"
                                />
                                <img
                                    v-else
                                    src="@/assets/images/logo/usdt.png"
                                    width="30px"
                                />
                            </template>
                        </div>
                    </template>
                    <template #cell(from)="{ value }">
                        <div>
                            <template v-if="search.type !== 'commission'">
                                <img
                                    v-if="
                                        value === 'xinCoin' ||
                                        value === 'bxinCoin'
                                    "
                                    src="@/assets/images/logo/xin.png"
                                    width="30px"
                                />
                                <img
                                    v-else
                                    src="@/assets/images/logo/usdt.png"
                                    width="30px"
                                />
                            </template>
                            <template v-else>
                                {{ value }}
                            </template>
                        </div>
                    </template>
                    <template #cell(to)="{ value }">
                        <div>
                            <img
                                v-if="
                                    value === 'xinCoin' || value === 'bxinCoin'
                                "
                                src="@/assets/images/logo/xin.png"
                                width="30px"
                            />
                            <img
                                v-else
                                src="@/assets/images/logo/usdt.png"
                                width="30px"
                            />
                        </div>
                    </template>
                    <template #cell(comment)="{ value }">
                        <div class="comment">
                            {{ ManipulateString(value) }}
                        </div>
                    </template>
                    <template #cell(claimed)="{ value }">
                        <div>
                            <span
                                :class="value === 1 ? 'success' : 'text-danger'"
                            >
                                {{
                                    value === 1
                                        ? 'Claimed'
                                        : value === -1
                                        ? 'Canceled'
                                        : 'Mined'
                                }}
                            </span>
                        </div>
                    </template>
                    <template #cell(reservedTime)="{ value }">
                        <div>
                            {{ getDateTime(value) }}
                        </div>
                    </template>
                    <template #cell(#)="row">
                        <div>
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </div>
                    </template>
                    <template #row-details="{ item }">
                        <div
                            v-if="search.type === 'reversecommission'"
                            class="details"
                        >
                            <div v-if="item.createdAt" class="mobile-data">
                                <div>{{ $t('createdDate') }}:</div>
                                <div>
                                    {{
                                        Number.isInteger(item.createdAt)
                                            ? getDateTime(item.createdAt * 1000)
                                            : getDateTime3(item.createdAt)
                                    }}
                                </div>
                            </div>
                            <div class="mobile-data">
                                <span>{{ $t('remaining') }}:</span>
                                <span>
                                    <span>
                                        {{
                                            Number(item.paid).toLocaleString(
                                                'en-US',
                                            )
                                        }}
                                        <i class="subtext">
                                            ({{ $t('settled') }})
                                        </i>
                                    </span>
                                    +
                                    <span>
                                        {{
                                            Number(item.amount).toLocaleString(
                                                'en-US',
                                            )
                                        }}
                                        <i class="subtext">
                                            ({{ $t('remaining') }})
                                        </i>
                                    </span>
                                    =
                                    <span>
                                        {{
                                            Number(
                                                item.amount + item.paid,
                                            ).toLocaleString('en-US')
                                        }}
                                    </span>
                                </span>
                            </div>
                            <div v-if="item.comment" class="mobile-data">
                                <span>{{ $t('comment') }}: </span>
                                <span class="font-weight-bold">
                                    {{ item.comment }}
                                </span>
                            </div>
                        </div>
                        <div v-else class="details">
                            <div v-if="item.transaction" class="mobile-data">
                                <div>Transaction:</div>
                                <a
                                    target="_blank"
                                    :href="`${url}${item.transaction}`"
                                >
                                    <span v-if="item.transaction">
                                        {{
                                            item.transaction.slice(0, 5) +
                                            '...' +
                                            item.transaction.slice(
                                                item.transaction.length - 10,
                                                item.transaction.length,
                                            )
                                        }}
                                    </span>
                                </a>
                            </div>
                            <div
                                v-if="typeof item.fee === 'number'"
                                class="mobile-data"
                            >
                                <span>Fee: </span>
                                <span>
                                    {{ item.fee }}
                                    <template v-if="item.currency">
                                        <img
                                            v-if="
                                                item.currency === 'xinCoin' ||
                                                item.currency === 'bxinCoin'
                                            "
                                            src="@/assets/images/logo/xin.png"
                                            width="30px"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/images/logo/usdt.png"
                                            width="30px"
                                        />
                                    </template>
                                    <template v-else>
                                        <img
                                            v-if="
                                                item.to === 'xinCoin' ||
                                                item.to === 'bxinCoin'
                                            "
                                            src="@/assets/images/logo/xin.png"
                                            width="30px"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/images/logo/usdt.png"
                                            width="30px"
                                        />
                                    </template>
                                </span>
                            </div>
                            <div v-if="item.createdAt" class="mobile-data">
                                <div>Time of creation:</div>
                                <div>
                                    {{
                                        Number.isInteger(item.createdAt)
                                            ? getDateTime(item.createdAt * 1000)
                                            : getDateTime3(item.createdAt)
                                    }}
                                </div>
                            </div>
                            <div v-if="item.status" class="mobile-data">
                                <span>Status: </span>
                                <span
                                    :class="
                                        item.status ? 'success' : 'text-danger'
                                    "
                                >
                                    {{ item.status ? 'Success' : 'Failed' }}
                                </span>
                            </div>
                            <div v-if="item.comment" class="mobile-data">
                                <span>Comment: </span>
                                <span class="font-weight-bold">
                                    {{ item.comment }}
                                </span>
                            </div>
                            <div v-if="item.currency" class="mobile-data">
                                <span>Currency: </span>
                                <span>
                                    <img
                                        v-if="
                                            item.currency === 'xinCoin' ||
                                            item.currency === 'bxinCoin'
                                        "
                                        src="@/assets/images/logo/xin.png"
                                        width="18px"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/images/logo/usdt.png"
                                        width="18px"
                                    />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #cell(settled)="{ item }">
                        {{ Number(item.paid) - Number(item.amount) }}
                    </template>
                    <template #cell(totalCom)="{ item }">
                        {{ Number(item.paid) + Number(item.amount) }}
                    </template>
                    <template #cell(manaType)="{ item }">
                        {{
                            item.userId === item.receiverId
                                ? $t('limitBreak')
                                : $t('transferMana')
                        }}
                    </template>
                    <template #cell(manaAmount)="{ item }">
                        <template v-if="item.userId === item.receiverId">
                            {{ item.amount }} USDT
                        </template>
                        <template v-else> {{ item.amount }} Mana </template>
                    </template>
                </b-table>
            </div>
            <div class="d-lg-none mobile table-responsive mt-4">
                <b-table
                    striped
                    hover
                    :items="History.list"
                    :fields="myMobileFields[search.type]"
                    show-empty
                    thead-class="customer-header"
                    no-local-sorting
                    @sort-changed="detectSort"
                >
                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>
                    <template #cell()="{ value }">
                        <div>{{ value }}</div>
                    </template>
                    <template #empty>
                        <h4 class="text-center my-3">No Data</h4>
                    </template>
                    <template #row-details="{ item }">
                        <div
                            v-if="search.type === 'reversecommission'"
                            class="details"
                        >
                            <div v-if="item.createdAt" class="mobile-data">
                                <div>{{ $t('createdDate') }}:</div>
                                <div>
                                    {{
                                        Number.isInteger(item.createdAt)
                                            ? getDateTime(item.createdAt * 1000)
                                            : getDateTime3(item.createdAt)
                                    }}
                                </div>
                            </div>
                            <div class="mobile-data">
                                <span>{{ $t('commission') }}:</span>
                                <span>
                                    <span>
                                        {{
                                            Number(item.paid).toLocaleString(
                                                'en-US',
                                            )
                                        }}
                                        <i class="subtext">
                                            ({{ $t('settled') }})
                                        </i>
                                    </span>
                                    +
                                    <span>
                                        {{
                                            Number(item.amount).toLocaleString(
                                                'en-US',
                                            )
                                        }}
                                        <i class="subtext">
                                            ({{ $t('remaining') }})
                                        </i>
                                    </span>
                                    =
                                    <span>
                                        {{
                                            Number(
                                                item.amount + item.paid,
                                            ).toLocaleString('en-US')
                                        }}
                                    </span>
                                </span>
                            </div>
                            <div v-if="item.comment" class="mobile-data">
                                <span>{{ $t('comment') }}: </span>
                                <span class="font-weight-bold">
                                    {{ item.comment }}
                                </span>
                            </div>
                        </div>
                        <div v-else class="details">
                            <div v-if="item.transaction" class="mobile-data">
                                <div>Transaction:</div>
                                <a
                                    target="_blank"
                                    :href="`${url}${item.transaction}`"
                                >
                                    <span v-if="item.transaction">
                                        {{
                                            item.transaction.slice(0, 5) +
                                            '...' +
                                            item.transaction.slice(
                                                item.transaction.length - 10,
                                                item.transaction.length,
                                            )
                                        }}
                                    </span>
                                </a>
                            </div>
                            <div
                                v-if="typeof item.fee === 'number'"
                                class="mobile-data"
                            >
                                <span>Fee: </span>
                                <span>
                                    {{ item.fee }}
                                    <template v-if="item.currency">
                                        <img
                                            v-if="
                                                item.currency === 'xinCoin' ||
                                                item.currency === 'bxinCoin'
                                            "
                                            src="@/assets/images/logo/xin.png"
                                            width="30px"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/images/logo/usdt.png"
                                            width="30px"
                                        />
                                    </template>
                                    <template v-else>
                                        <img
                                            v-if="
                                                item.to === 'xinCoin' ||
                                                item.to === 'bxinCoin'
                                            "
                                            src="@/assets/images/logo/xin.png"
                                            width="30px"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/images/logo/usdt.png"
                                            width="30px"
                                        />
                                    </template>
                                </span>
                            </div>
                            <div v-if="item.createdAt" class="mobile-data">
                                <div>Time of creation:</div>
                                <div>
                                    {{
                                        Number.isInteger(item.createdAt)
                                            ? getDateTime(item.createdAt * 1000)
                                            : getDateTime3(item.createdAt)
                                    }}
                                </div>
                            </div>
                            <div v-if="item.status" class="mobile-data">
                                <span>Status: </span>
                                <span
                                    :class="
                                        item.status ? 'success' : 'text-danger'
                                    "
                                >
                                    {{ item.status ? 'Success' : 'Failed' }}
                                </span>
                            </div>
                            <div v-if="item.comment" class="mobile-data">
                                <span>Comment: </span>
                                <span class="font-weight-bold">
                                    {{ item.comment }}
                                </span>
                            </div>
                            <div v-if="item.currency" class="mobile-data">
                                <span>Currency: </span>
                                <span>
                                    <img
                                        v-if="
                                            item.currency === 'xinCoin' ||
                                            item.currency === 'bxinCoin'
                                        "
                                        src="@/assets/images/logo/xin.png"
                                        width="18px"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/images/logo/usdt.png"
                                        width="18px"
                                    />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #cell(action)="data">
                        <div class="text-uppercase">
                            {{ data.item.action }}
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <div>
                            <span
                                :class="
                                    data.item.status ? 'success' : 'text-danger'
                                "
                            >
                                {{ data.item.status ? 'Success' : 'Failed' }}
                            </span>
                        </div>
                    </template>
                    <template #cell(#)="row">
                        <div>
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </div>
                    </template>
                    <template #cell(currency)="{ value }">
                        <div>
                            <img
                                v-if="
                                    value === 'xinCoin' || value === 'bxinCoin'
                                "
                                src="@/assets/images/logo/xin.png"
                                width="30px"
                            />
                            <img
                                v-else
                                src="@/assets/images/logo/usdt.png"
                                width="30px"
                            />
                        </div>
                    </template>
                    <template #cell(amount)="{ value }">
                        <div>
                            {{ TruncateToDecimals2(value, '', 4) }}
                        </div>
                    </template>
                    <template #cell(from)="{ value }">
                        <div>
                            <template v-if="search.type !== 'commission'">
                                <img
                                    v-if="
                                        value === 'xinCoin' ||
                                        value === 'bxinCoin'
                                    "
                                    src="@/assets/images/logo/xin.png"
                                    width="30px"
                                />
                                <img
                                    v-else
                                    src="@/assets/images/logo/usdt.png"
                                    width="30px"
                                />
                            </template>
                            <template v-else>
                                {{ value }}
                            </template>
                        </div>
                    </template>
                    <template #cell(to)="{ value }">
                        <div>
                            <img
                                v-if="
                                    value === 'xinCoin' || value === 'bxinCoin'
                                "
                                src="@/assets/images/logo/xin.png"
                                width="30px"
                            />
                            <img
                                v-else
                                src="@/assets/images/logo/usdt.png"
                                width="30px"
                            />
                        </div>
                    </template>
                    <template #cell(comment)="{ value }">
                        <div class="comment">
                            {{ value }}
                        </div>
                    </template>
                    <template #cell(claimed)="{ value }">
                        <div>
                            <span
                                :class="value === 1 ? 'success' : 'text-danger'"
                            >
                                {{
                                    value === 1
                                        ? 'Claimed'
                                        : value === -1
                                        ? 'Canceled'
                                        : 'Mined'
                                }}
                            </span>
                        </div>
                    </template>
                    <template #cell(reservedTime)="{ value }">
                        <div>
                            {{ getDateTime(value) }}
                        </div>
                    </template>
                    <template #cell(remainingReserve)="{ item }">
                        <div>
                            <div>
                                <div>
                                    {{
                                        Number(item.amount).toLocaleString(
                                            'en-US',
                                        )
                                    }}
                                    <img
                                        v-if="
                                            item.currency === 'xinCoin' ||
                                            item.currency === 'bxinCoin'
                                        "
                                        src="@/assets/images/logo/xin.png"
                                        width="24px"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/images/logo/usdt.png"
                                        width="24px"
                                    />
                                </div>
                                <div>
                                    <div
                                        v-if="item.status === -1"
                                        class="text-danger"
                                    >
                                        <strong>{{ $t('canceled') }}</strong>
                                    </div>
                                    <div
                                        v-if="item.status === 0"
                                        class="text-warning"
                                    >
                                        <strong>{{ $t('pending') }}</strong>
                                    </div>
                                    <div
                                        v-if="item.status === 1"
                                        class="received"
                                    >
                                        <strong>{{ $t('received') }}</strong>
                                    </div>
                                    <div
                                        v-if="item.status === 2"
                                        class="text-warning"
                                    >
                                        <strong>{{ $t('expired') }}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #cell(manaType)="{ item }">
                        {{
                            item.userId === item.receiverId
                                ? 'Limit break'
                                : 'Tranfer'
                        }}
                    </template>
                    <template #cell(manaAmount)="{ item }">
                        <template v-if="item.userId === item.receiverId">
                            {{ item.amount }} USDT
                        </template>
                        <template v-else> {{ item.amount }} Mana </template>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="table-paginate">
            <Paginate @current="onChangePage" :totalPages="History.total" />
        </div>
    </b-container>
</template>
<style lang="scss">
.activity-tab {
    label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px;
        color: #0087cc;
    }

    .date-form {
        .form-control {
            background: transparent !important;
        }
    }

    .min-200 {
        min-width: 150px;
        width: max-content;
    }

    .group-hist {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 15px;
        padding-right: 5px;
        position: relative;

        .select-type {
            max-width: 275px;
            height: 48px;
            text-transform: capitalize;
        }

        .b-form-datepicker,
        select {
            max-width: 275px;
            height: 40px;
            background: rgba(33, 41, 48, 0.6);
            color: #fff;
            border-radius: 100px;
            border: none;

            option {
                background: #00000b;
            }

            button {
                &#datepicker,
                &#datepicker-2 {
                    color: #fff;

                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;

                    color: #dcdcdc;
                }
            }

            label.form-control {
                color: #fff !important;
                display: flex;
                align-items: center;
            }
        }

        img {
            height: 15px;
            width: auto;
            position: absolute;
            right: 7px;
            bottom: 0;
            top: 0;
            margin: auto;
            display: none;
        }
    }

    .bede-btn {
        max-width: 221px;
        height: 50px;
    }

    .refresh {
        color: #000;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        background: transparent;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        width: 40px;
        height: 40px;
        padding: 0;
        border: 1px solid #00ffff;
        border-radius: 100%;

        @media (max-width: 575px) {
            width: 35px;
            height: 35px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }

    .filter-history {
        flex: 1;
        // max-width: 255px;
        min-width: 220px;

        @media (max-width: 575px) {
            max-width: 300px;
            min-width: 100%;
        }
    }

    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 25px;
        font-weight: 900;

        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(105% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }

    .table-responsive {
        background: #1478b0;
        border-radius: 22px;

        .customer-header {
            background-color: transparent;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #fff;
        }

        .table th,
        .table td {
            border: none;
        }

        table {
            thead {
                tr {
                    th {
                        white-space: nowrap;
                    }
                }
            }

            tbody {
                tr {
                    height: 70px;
                    border-radius: 12px;
                    background: transparent;

                    &:nth-child(odd) {
                        background: #e1e1e175;
                    }

                    td {
                        background: none;
                        padding: 0.75rem;
                        color: #fff;
                        font-weight: 500;
                        vertical-align: middle;
                        > div {
                            height: 80px;
                            overflow: auto;
                            &:not(.details) {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                > div {
                                    min-width: 0;
                                    flex-grow: 1;
                                }
                            }
                        }

                        .details {
                            margin-top: -10px;
                            height: 180px;
                        }

                        .mobile-data {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            line-height: 1;
                            padding: 5px 0px;
                            border-bottom: 1px dashed #f1f1f1;
                        }

                        > span {
                            height: 70px;
                            display: flex;
                            padding: 0 0.75rem;
                            align-items: center;
                            margin-bottom: 0;
                            justify-content: center;
                            backdrop-filter: blur(15.899999618530273px);
                        }
                    }
                }
            }
        }
    }

    .table-statistical {
        // border: 1px solid #00ffff;
        max-width: 500px;
        margin: 15px auto;

        .table-item {
            // background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Helvetica';
            justify-content: space-between;

            &:nth-of-type(even) {
                box-shadow: inset 0px 0px 2005px rgba(0, 255, 255, 0.2);
            }

            .left {
                flex: 0 0 calc(100% - 300px);
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .right {
                flex: 0 0 250px;
                border-left: 1px solid #00ffff;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (min-width: 992px) {
                    justify-content: flex-start;
                    text-align: left;
                    padding-left: 5%;
                }
            }

            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;

                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }

            @media (max-width: 575px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;

                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
    }

    .comment {
        font-size: 14px;
        color: #fff;
        font-weight: 600;
    }

    .success {
        color: #70dd89;
    }
}
</style>

<style lang="scss" scoped>
.mi-input {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 30px;

    .wrap {
        position: relative;

        > input {
            border: none;
            background-color: #cbd5e1;
            border-radius: 6px;
            color: #111827;
            flex-grow: 1;
            min-width: 0;
            padding: 8px 15px;
        }

        .submit {
            position: absolute;
            top: 2px;
            background-color: #0087cc;
            bottom: 2px;
            right: 2px;
            width: 40px;
            padding: 0;
            border-radius: 6px;
            color: white;
        }
    }
}

.received {
    color: #22c55e;
}
.subtext {
    color: #d4d4d4;
    font-size: 0.875em;
}
</style>
